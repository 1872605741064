


























































































































































































import { Component, Vue } from 'vue-property-decorator'
import ImgView from '@/components/ImgView/index.vue'

@Component({
  components: {
    ImgView
  }
})
export default class extends Vue {
  $constants: any
  remark = ''
  imgUrl = ''
  loading = false
  ownInfo: any = {} //业主信息
  houseInfo: any = {} //房屋信息
  carPlacesInfo: any = [] //车位信息

  created() {
    this.getPeopleDetail(this.$route.query.id)
  }
  //查询详情
  getPeopleDetail(id: any) {
    this.loading = true
    this.$api.resident
      .getPeopleDetailById(id)
      .then((res: any) => {
        const data = res.data
        if (data && data.success) {
          this.ownInfo = data.data.resident
          this.houseInfo = data.data
          this.carPlacesInfo = data.data.carPlaces
        } else {
          this.$message.error(data.msg)
          this.loading = false
        }
      })
      .catch(() => {
        this.loading = false
      })
  }
  // 返回
  goback() {
    this.$router.push('/residents/people-list')
  }
  //保存
  save() {
    this.loading = true
    const params = {
      id: this.ownInfo.id,
      remark: this.ownInfo.remark || this.remark
    }
    this.$api.resident.updateResident(params).then((res: any) => {
      const data = res.data
      this.$message.success(data.msg)
      if (data && data.success) {
        this.$router.push('/residents/people-list')
      }
    })
  }
  //点击复制电话号码
  onCopy() {
    this.$message.success('复制电话号码成功')
  }
  gotoPeople(item: any) {
    this.$router.push({
      path: '/residents/people-list',
      query: {
        buildingNo: item.buildingNo + ''
      }
    })
  }

  gotoRepair(item: any) {
    this.$router.push({
      path: '/property/repair',
      query: {
        buildingNo: item.buildingNo + ''
      }
    })
  }
  // 查看图片
  viewImg(img: any) {
    this.imgUrl = img
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }
}
